<template>
  <div class="container">
    <headerTab></headerTab>
    <div class="content">
      <div class="r_title">订单详情</div>
      <div class="order_text">
        <div>
          <div>订单：{{tableData.trade_no}} </div>
          <div class="size_text" style="color:#C7C7C7">{{tableData.create_time}}</div>
        </div>
        <div>
          <div>单价：{{tableData.sales.shares_price}}元/股</div>
          <div>x{{tableData.sales.shares}}</div>
        </div>
        <div style="margin-bottom:0">
          <div>居间费率：{{tableData.sales.sales_tax_rate_text}} </div>
          <div>订单金额：¥{{tableData.sales.shares_amount}}</div>
        </div>
      </div>
      <div class="r_price">实际转让款:￥{{tableData.sales.shares_sales}}</div>
      <div class="r_title">买家打款凭证</div>
      <div class="r_imglist" v-for="(item,index) in tableData.remit_info" :key="index">
        <div class="r_time">{{item.remit_at}}</div>
        <img @click="ImagePreview(item.voucher[0].url)" :src="item.voucher[0].url" />
        <div class="r_message" v-if="item.message_error">备注：{{item.message_error}}</div>
      </div>
      <div class="r_title">买家信息</div>
      <div class="r_putway">
        <div>账户主体:{{tableData.trade_user.union_info.bank.userName}}</div>
        <div>对公账号:{{tableData.trade_user.union_info.bank.number}}</div>
        <div>开户行详情:{{tableData.trade_user.union_info.bank.bank}}</div>
      </div>
      <div v-if="queryList.index==1">
        <div class="r_title">退款凭证<span>*打款前请认真核对打款账户，最多上传2张凭证</span></div>
        <uploadImg :uploadData="uploadData" @uploadImg="uploadImg"></uploadImg>
        <div class="r_title">备注</div>
        <textarea v-model="message"></textarea>
        <div class="r_hint">*温馨提示：请按实际情况操作退款给买方，一经查实恶心操作，直接注销账号！</div>
        <div class="r_submit" @click="submit">提交</div>
      </div>

    </div>

  </div>
</template>

<script>
import headerTab from "../../components/headerTab.vue";
import uploadImg from "../../components/uploadImg.vue";
import * as apiCommon from "@/api/common";
import { returnProof,returnDetails_api} from "@/api/deal";
import { Toast } from "vant";
import { ImagePreview } from 'vant';
export default {
  data() {
    return {
      fileList: [],
      queryList: {},
      tableData: {},
      uploadData: {
        getToken: "",
        imgConfig: "{ useCdnDomain: true }"
      },
      img: [],
      message: ""
    };
  },
  components: {
    headerTab,
    uploadImg
  },
  created() {
    document.title = "退款详情";
    this.queryList = this.$route.query;
    this.getDetails(this.queryList.id);
    this.getToken();
  },
  methods: {
    ImagePreview(img){
      ImagePreview([img])
    },
    beforeRead() {},
    oversize() {},
    imgBtnClone() {},
    async getDetails(id) {
      returnDetails_api(id).then(res => {
        if (res.code == 0) {
          this.tableData = res.data;
          this.$forceUpdate();
        }
      });
    },
    async getToken() {
      console.log(2);
      let res = await apiCommon.getImgToken();
      if (!res || typeof res === "string" || res.error)
        return this.$toast.fail(res ? res.error || res : "获取七牛云信息失败!");
      this.uploadData.imgToken = res.data;
      console.log(this.imgToken, "1223131");
    },
    uploadImg(data) {
      this.img = data;
      console.log(this.img, "2");
    },
    submit() {
      let data = {
        remit_refund_info: {
          refundProof: this.img,
          message: this.message
        }
      };
      returnProof(this.queryList.id, data).then(res => {
        if (res.code == 0) {
          console.log(res);
          Toast({
            message: "操作成功",
            icon: "success",
            duration: 1000
          });
          this.$router.go(-1);
        }
      });
    }
  }
};
</script>
<style>
.container {
  padding-left: 20px;
  box-sizing: border-box;
}
.order_text {
  font-size: 30px;
  color: #585858;
  border-bottom: 1px solid #ececec;
  padding-bottom: 15px;
}
.content {
  width: 630px;
  margin: 0 auto;
}
.order_text div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.r_title {
  font-size: 40px;
  color: #0f87ff;
  font-weight: bold;
  margin: 40px 0 20px;
}
.r_title span {
  font-size: 20px;
  color: #ff3e46;
}
.r_price {
  font-size: 30px;
  color: #ff3e46;
  text-align: right;
  margin: 20px auto 50px;
}
.r_imglist {
  display: flex;
  font-size: 25px;
  color: #585858;
  justify-content: space-between;
  position: relative;
  height: 180px;
  border-bottom: 1px solid #ececec;
}
.r_imglist img {
  width: 150px;
  height: 150px;
  margin-right: 80px;
}
.r_time {
  margin-left: 58px;
  line-height: 150px;
}
.r_message {
  position: absolute;
  bottom: 10px;
  left: 20px;
}
.r_putway {
  color: #585858;
  font-size: 30px;
  margin-top: 50px;
  margin-left: 50px;
}
.r_putway div {
  margin-bottom: 45px;
}
.imgClass {
  position: relative;
  display: flex;
}
.imgClass img {
  width: 100px;
  height: 100px;
}
.r_hint {
  font-size: 25px;
  color: #ff3e46;
  width: 477px;
  margin: 30px auto 0;
}
.r_submit {
  width: 388px;
  height: 90px;
  background: #deb77a;
  opacity: 1;
  border-radius: 50px;
  text-align: center;
  line-height: 90px;
  font-size: 35px;
  color: #fff;
  margin: 30px auto;
}
.order_text .size_text {
  font-size: 25px;
  
}
</style>